import React, { Component } from "react";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Alert,
    Button,
    Card,
    Checkbox,
    Drawer,
    DrawerActions,
    DrawerCloseButton,
    DrawerColorVariant,
    DrawerContent,
    DrawerContentBody,
    DrawerHead,
    DrawerPanelContent,
    Form,
    FormGroup,
    FormSection,
    Grid,
    PageGroup,
    PageSection,
    PageSectionVariants,
    Tab,
    Tabs,
    TabTitleIcon,
    TabTitleText,
    TextInput,
    Title
} from "@patternfly/react-core";

import {
    ListIcon,
    PauseIcon,
    PlayIcon,
    PlusCircleIcon,
    SyncAltIcon
} from "@patternfly/react-icons";

import {
    TableComposable,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@patternfly/react-table";

import SimulationDetails from "./SimulationDetails";
import { AppSettings } from "./AppSettings";
import "./TableIcons.css";

class SimulationList extends Component {
    static COLUMNS = [
        {field: "serialNumber", label: "fe_common_serial-number", width: 15},
        {field: "localizedStarted", label: "fe_simulation", width: 15},
        {field: "status", label: "fe_common_status", width: 25},
        {field: "numAnchors", label: "fe_common_anchor", width: 10},
        {field: "localizedCreatedAt", label: "fe_simulation_created", width: 15},
        {field: "createdBy", label: "user_role_user", width: 20}
    ]

    static sortRows(rows, index, direction) {
        const field = SimulationList.COLUMNS[index].field === "localizedCreatedAt" ? "createdAt" : SimulationList.COLUMNS[index].field
        return rows.sort((g1, g2) => {
            if (direction === "asc") {
                if (field === "createdAt" || field === "numAnchors") {
                    return g1[field] - g2[field]
                }
                return g1[field].localeCompare(g2[field])
            }
            if (field === "createdAt" || field === "numAnchors") {
                return g2[field] - g1[field]
            }
            return g2[field].localeCompare(g1[field])
        })
    }

    static started2Icon(started) {
        return started ? <PlayIcon className="Success"/> : <PauseIcon className="Danger"/>
    }

    constructor(props) {
        super(props)

        this.simulations = []

        this.state = {
            simulations: [],
            activeTabKey: 0,
            selectedRowIndex: -1,
            activeSortIndex: 0,
            activeSortDirection: "asc",
            createFirmwareVersion: "00.01.12345678",
            createBatteryLevel: 100,
            createErrorMessage: "",
            createConnectors: 4,
            createNumAnchors: 0
        }

        this.loadSimulations = this.loadSimulations.bind(this)
        this.onTabSelect = this.onTabSelect.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.onSort = this.onSort.bind(this)
        this.drawerCloseClicked = this.drawerCloseClicked.bind(this)
        this.resetCreateSimulationState = this.resetCreateSimulationState.bind(this)
        this.onCreateFirmwareVersionChanged = this.onCreateFirmwareVersionChanged.bind(this)
        this.onCreateBatteryLevelChanged = this.onCreateBatteryLevelChanged.bind(this)
        this.onCreateErrorMessageChanged = this.onCreateErrorMessageChanged.bind(this)
        this.onCreateConnectorsChanged = this.onCreateConnectorsChanged.bind(this)
        this.onAnchorCalibrationSignalChanged = this.onAnchorCalibrationSignalChanged.bind(this)
        this.onAnchorCalibrationForceChanged = this.onAnchorCalibrationForceChanged.bind(this)
        this.onAnchorStartSignalChanged = this.onAnchorStartSignalChanged.bind(this)
        this.onAnchorTargetSignalChanged = this.onAnchorTargetSignalChanged.bind(this)
        this.onAnchorDurationChanged = this.onAnchorDurationChanged.bind(this)
        this.onAnchorPortChanged = this.onAnchorPortChanged.bind(this)
        this.onAnchorRepeatChanged = this.onAnchorRepeatChanged.bind(this)
        this.onAnchorRevertChanged = this.onAnchorRevertChanged.bind(this)
        this.onAddAnchor = this.onAddAnchor.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount() {
        this.loadSimulations()
        this.resetCreateSimulationState()
    }

    resetCreateSimulationState() {
        this.setState({
            activeSortDirection: "asc",
            createFirmwareVersion: "00.01.12345678",
            createBatteryLevel: 100,
            createErrorMessage: "",
            createConnectors: 4,
            createNumAnchors: 0
        })
    }

    loadSimulations() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/simulations/list`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.simulations = data
                            this.setState(prevState => {
                                return {
                                    selectedRowIndex: -1,
                                    simulations: SimulationList.sortRows(data, prevState.activeSortIndex, prevState.activeSortDirection)
                                }
                            })
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }


    render() {
        const drawerExpanded = this.state.selectedRowIndex !== -1
        const serialNumber = drawerExpanded ? this.state.simulations[this.state.selectedRowIndex].serialNumber : ""

        return (
            <Translation>
                { t =>
                    <Drawer isExpanded={drawerExpanded} position="right">
                        <DrawerContent panelContent={
                            <DrawerPanelContent colorVariant={DrawerColorVariant.light200} minSize="600px">
                                <DrawerHead>
                                    <Title headingLevel="h1">{t("fe_simulation")}</Title>
                                    <DrawerActions>
                                        <DrawerCloseButton onClick={this.drawerCloseClicked}/>
                                    </DrawerActions>
                                </DrawerHead>
                                {
                                    drawerExpanded && <SimulationDetails
                                        app={this.props.app}
                                        serialNumber={serialNumber}
                                        onSimulationToggle={this.loadSimulations}
                                    />
                                }
                            </DrawerPanelContent>
                        }>
                            <DrawerContentBody>
                                <PageGroup>
                                    <PageSection variant={PageSectionVariants.light}>
                                        <Title className="Title" headingLevel="h1">{t("fe_simulator_title")}</Title>
                                        <Tabs activeKey={this.state.activeTabKey} onSelect={this.onTabSelect}
                                              isBox={true}>
                                            <Tab eventKey={0} title={<><TabTitleIcon><ListIcon/></TabTitleIcon>
                                                <TabTitleText>{t("fe_simulator_list")}</TabTitleText></>}>
                                                <Card component="div">
                                                    <TableComposable variant="compact" borders={true} isStickyHeader>
                                                        <Thead>
                                                            <Tr>
                                                                {
                                                                    SimulationList.COLUMNS.map((column, index) => {
                                                                        const sortParams = {
                                                                            sort: {
                                                                                sortBy: {
                                                                                    index: this.state.activeSortIndex,
                                                                                    direction: this.state.activeSortDirection
                                                                                },
                                                                                onSort: this.onSort,
                                                                                columnIndex: index
                                                                            }
                                                                        };
                                                                        return <Th key={index} {...sortParams}
                                                                                   width={column.width}>{t(column.label)}</Th>
                                                                    })
                                                                }
                                                                <Th key="refresh" className="RefreshButton">
                                                                    <Button variant="plain" isInline
                                                                            aria-label="Refresh"
                                                                            onClick={this.loadSimulations}><SyncAltIcon/></Button>
                                                                </Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {
                                                                this.state.simulations.map((row, rowIndex) => {
                                                                    return (
                                                                        <Tr isHoverable key={rowIndex}
                                                                            onRowClick={event => this.onRowClick(event, rowIndex)}
                                                                            isRowSelected={this.state.selectedRowIndex === rowIndex}>
                                                                            <Td key={`${rowIndex}_0`}
                                                                                dataLabel={t(SimulationList.COLUMNS[0].label)}>
                                                                                {row["serialNumber"]}
                                                                            </Td>
                                                                            <Td key={`${rowIndex}_1`}
                                                                                dataLabel={t(SimulationList.COLUMNS[1].label)}>
                                                                                {SimulationList.started2Icon(row["started"])}
                                                                                {row["localizedStarted"]}
                                                                            </Td>
                                                                            <Td key={`${rowIndex}_2`}
                                                                                dataLabel={t(SimulationList.COLUMNS[2].label)}>
                                                                                {row["status"]}
                                                                            </Td>
                                                                            <Td key={`${rowIndex}_3`}
                                                                                dataLabel={t(SimulationList.COLUMNS[3].label)}>
                                                                                {row["numAnchors"]}
                                                                            </Td>
                                                                            <Td key={`${rowIndex}_4`}
                                                                                dataLabel={t(SimulationList.COLUMNS[4].label)}>
                                                                                {row["localizedCreatedAt"]}
                                                                            </Td>
                                                                            <Td key={`${rowIndex}_5`}
                                                                                dataLabel={t(SimulationList.COLUMNS[5].label)}>
                                                                                {row["createdBy"]}
                                                                            </Td>
                                                                        </Tr>
                                                                    )
                                                                })
                                                            }
                                                        </Tbody>
                                                    </TableComposable>
                                                </Card>
                                            </Tab>
                                            <Tab eventKey={1}
                                                 title={<><TabTitleIcon><PlusCircleIcon/></TabTitleIcon> <TabTitleText>
                                                     {t("fe_simulator_add")}
                                                 </TabTitleText></>}>
                                                {
                                                    this.state.successMessage ?
                                                        <Alert isInline className="TopSpacer" variant="success"
                                                               title={this.state.successMessage}/> : false
                                                }
                                                <Form className="TopSpacer">
                                                    <FormSection>
                                                        <FormGroup label={t("fe_firmware_version")} isRequired
                                                                   fieldId="firmware-version"
                                                                   helperText={t("fe_simulator_version-hint")}>
                                                            <TextInput id="firmware-version" name="firmware-version"
                                                                       onChange={this.onCreateFirmwareVersionChanged}
                                                                       value={this.state.createFirmwareVersion}/>
                                                        </FormGroup>
                                                        <FormGroup label={t("fe_common_battery-charge")} isRequired
                                                                   fieldId="battery-level"
                                                                   helperText={t("fe_simulator_battery-charge-hint")}>
                                                            <TextInput type="number" id="battery-level"
                                                                       name="battery-level"
                                                                       onChange={this.onCreateBatteryLevelChanged}
                                                                       value={this.state.createBatteryLevel}/>
                                                        </FormGroup>
                                                        <FormGroup label={t("fe_simulator_error-message")} fieldId="error-message"
                                                                   helperText={t("fe_simulator_error-message-hint")}>
                                                            <TextInput id="error-message" name="error-message"
                                                                       onChange={this.onCreateErrorMessageChanged}
                                                                       value={this.state.createErrorMessage}/>
                                                        </FormGroup>
                                                        <FormGroup label={t("gateway_prop_ports")} isRequired fieldId="connectors"
                                                                   helperText={t("fe_simulator_ports-hint")}>
                                                            <TextInput type="number" id="connectors" name="connectors"
                                                                       onChange={this.onCreateConnectorsChanged}
                                                                       value={this.state.createConnectors}/>
                                                        </FormGroup>
                                                    </FormSection>
                                                    {
                                                        [...Array(this.state.createNumAnchors).keys()].map(anchorNumber => {
                                                            return (
                                                                <FormSection key={`section${anchorNumber}`}
                                                                             title={`${t("anchor")} ${anchorNumber + 1}`}
                                                                             titleElement="h2">
                                                                    <Grid hasGutter md={3}>
                                                                        <FormGroup
                                                                            label={t("anchor_prop_factory-cp1-signal")}
                                                                            isRequired
                                                                            fieldId={`anchor-${anchorNumber}-cp1-signal`}
                                                                            helperText={t("fe_simulator_cp1-signal-hint")}>
                                                                            <TextInput type="number"
                                                                                       id={`anchor-${anchorNumber}-cp1-signal`}
                                                                                       name="cp1-signal"
                                                                                       onChange={value => this.onAnchorCalibrationSignalChanged(anchorNumber, 1, value)}
                                                                                       value={this.state[`anchor${anchorNumber}/cp1Signal`]}/>
                                                                        </FormGroup>
                                                                        <FormGroup label={t("anchor_prop_factory-cp1-load")}
                                                                                   isRequired
                                                                                   fieldId={`anchor-${anchorNumber}-cp1-force`}
                                                                                   helperText={t("fe_simulator_load-hint")}>
                                                                            <TextInput type="number"
                                                                                       id={`anchor-${anchorNumber}-cp1-force`}
                                                                                       name="cp1-force"
                                                                                       onChange={value => this.onAnchorCalibrationForceChanged(anchorNumber, 1, value)}
                                                                                       value={this.state[`anchor${anchorNumber}/cp1Force`]}/>
                                                                        </FormGroup>
                                                                        <FormGroup
                                                                            label={t("anchor_prop_factory-cp2-signal")}
                                                                            isRequired
                                                                            fieldId={`anchor-${anchorNumber}-cp2-signal`}
                                                                            helperText={t("fe_simulator_cp2-signal-hint")}>
                                                                            <TextInput type="number"
                                                                                       id={`anchor-${anchorNumber}-cp2-signal`}
                                                                                       name="cp2-signal"
                                                                                       onChange={value => this.onAnchorCalibrationSignalChanged(anchorNumber, 2, value)}
                                                                                       value={this.state[`anchor${anchorNumber}/cp2Signal`]}/>
                                                                        </FormGroup>
                                                                        <FormGroup label={t("anchor_prop_factory-cp2-load")}
                                                                                   isRequired
                                                                                   fieldId={`anchor-${anchorNumber}-cp1-force`}
                                                                                   helperText={t("fe_simulator_cp2-load-hint")}>
                                                                            <TextInput type="number"
                                                                                       id={`anchor-${anchorNumber}-cp2-force`}
                                                                                       name="cp2-force"
                                                                                       onChange={value => this.onAnchorCalibrationForceChanged(anchorNumber, 2, value)}
                                                                                       value={this.state[`anchor${anchorNumber}/cp2Force`]}/>
                                                                        </FormGroup>
                                                                        <FormGroup label={t("fe_simulator_signal-start")}
                                                                                   isRequired
                                                                                   fieldId={`anchor-${anchorNumber}-start-signal`}
                                                                                   helperText={t("fe_simulator_signal-start-hint")}>
                                                                            <TextInput type="number"
                                                                                       id={`anchor-${anchorNumber}-start-signal`}
                                                                                       name="start-signal"
                                                                                       onChange={value => this.onAnchorStartSignalChanged(anchorNumber, value)}
                                                                                       value={this.state[`anchor${anchorNumber}/startSignal`]}/>
                                                                        </FormGroup>
                                                                        <FormGroup label={t("fe_simulator_signal-end")}
                                                                                   isRequired
                                                                                   fieldId={`anchor-${anchorNumber}-target-signal`}
                                                                                   helperText={t("fe_simulator_signal-end-hint")}>
                                                                            <TextInput type="number"
                                                                                       id={`anchor-${anchorNumber}-target-signal`}
                                                                                       name="target-signal"
                                                                                       onChange={value => this.onAnchorTargetSignalChanged(anchorNumber, value)}
                                                                                       value={this.state[`anchor${anchorNumber}/targetSignal`]}/>
                                                                        </FormGroup>
                                                                        <FormGroup label={t("fe_simulator_duration")} isRequired
                                                                                   fieldId={`anchor-${anchorNumber}-duration`}
                                                                                   helperText={t("fe_simulator_duration-hint")}>
                                                                            <TextInput type="number"
                                                                                       id={`anchor-${anchorNumber}-duration`}
                                                                                       name="duration"
                                                                                       onChange={value => this.onAnchorDurationChanged(anchorNumber, value)}
                                                                                       value={this.state[`anchor${anchorNumber}/duration`]}/>
                                                                        </FormGroup>
                                                                        <FormGroup label={t("fe_anchor_port")} isRequired
                                                                                   fieldId={`anchor-${anchorNumber}-port`}
                                                                                   helperText={t("fe_simulator_port-hint")}>
                                                                            <TextInput type="number"
                                                                                       id={`anchor-${anchorNumber}-port`}
                                                                                       name="port"
                                                                                       onChange={value => this.onAnchorPortChanged(anchorNumber, value)}
                                                                                       value={this.state[`anchor${anchorNumber}/port`]}/>
                                                                        </FormGroup>
                                                                        <FormGroup
                                                                            label={t("fe_simulator_behavior")}
                                                                            helperText={t("fe_simulator_behavior-hint")}>
                                                                            <Checkbox label={t("fe_simulator_repeat")}
                                                                                      id={`anchor-${anchorNumber}-repeat`}
                                                                                      onChange={checked => this.onAnchorRepeatChanged(anchorNumber, checked)}
                                                                                      isChecked={this.state[`anchor${anchorNumber}/repeat`]}/>
                                                                            <Checkbox label={t("fe_simulator_reverse")}
                                                                                      id={`anchor-${anchorNumber}-revert`}
                                                                                      onChange={checked => this.onAnchorRevertChanged(anchorNumber, checked)}
                                                                                      isChecked={this.state[`anchor${anchorNumber}/revert`]}/>
                                                                        </FormGroup>
                                                                    </Grid>
                                                                </FormSection>
                                                            )
                                                        })
                                                    }
                                                    <ActionGroup>
                                                        <Button
                                                            isDisabled={this.state.createConnectors === this.state.createNumAnchors}
                                                            variant="secondary"
                                                            onClick={this.onAddAnchor}>{t("fe_simulator_add-anchor")}</Button>
                                                        <Button
                                                            variant="primary"
                                                            onClick={this.onSubmit}>{t("fe_simulator_create")}</Button>
                                                    </ActionGroup>
                                                </Form>
                                            </Tab>
                                        </Tabs>
                                    </PageSection>
                                </PageGroup>
                            </DrawerContentBody>
                        </DrawerContent>
                    </Drawer>
                }
            </Translation>
        )
    }

    onTabSelect(event, tabIndex) {
        this.setState({
            activeTabKey: tabIndex,
            selectedRowIndex: -1
        })
        if (tabIndex === 0) {
            this.loadSimulations()
        }
    }

    onRowClick(event, rowIndex) {
        this.setState(prevState => {
            return {
                selectedRowIndex: prevState.selectedRowIndex === rowIndex ? -1 : rowIndex
            }
        })
    }

    drawerCloseClicked() {
        this.setState({selectedRowIndex: -1});
    }

    onSort(event, index, direction) {
        this.setState(prevState => {
            return {
                selectedRowIndex: -1,
                activeSortIndex: index,
                activeSortDirection: direction,
                simulations: SimulationList.sortRows(prevState.simulations, index, direction)
            }
        })
    }

    onCreateFirmwareVersionChanged(value) {
        this.setState({createFirmwareVersion: value})
    }

    onCreateBatteryLevelChanged(value) {
        const numericValue = Number(value)
        if (numericValue >= 0 && numericValue <= 100) {
            this.setState({createBatteryLevel: numericValue})
        }
    }

    onCreateErrorMessageChanged(value) {
        this.setState({createErrorMessage: value})
    }

    onCreateConnectorsChanged(value) {
        const numericValue = Math.round(Number(value))
        if (numericValue >= 1 && numericValue <= 8 && numericValue >= this.state.createNumAnchors) {
            this.setState({createConnectors: numericValue})
        }
    }

    onAnchorCalibrationSignalChanged(anchorNumber, pointNumber, value) {
        const numericValue = Number(value)
        this.setState({
            [`anchor${anchorNumber}/cp${pointNumber}Signal`]: numericValue
        })
    }

    onAnchorCalibrationForceChanged(anchorNumber, pointNumber, value) {
        const numericValue = Number(value)
        this.setState({
            [`anchor${anchorNumber}/cp${pointNumber}Force`]: numericValue
        })
    }

    onAnchorStartSignalChanged(anchorNumber, value) {
        const numericValue = Number(value)
        this.setState({
            [`anchor${anchorNumber}/startSignal`]: numericValue
        })
    }

    onAnchorTargetSignalChanged(anchorNumber, value) {
        const numericValue = Number(value)
        this.setState({
            [`anchor${anchorNumber}/targetSignal`]: numericValue
        })
    }

    onAnchorDurationChanged(anchorNumber, value) {
        const numericValue = Math.round(Number(value))
        if (numericValue > 1) {
            this.setState({
                [`anchor${anchorNumber}/duration`]: numericValue
            })
        }
    }

    onAnchorPortChanged(anchorNumber, value) {
        const numericValue = Math.round(Number(value))
        if (numericValue >= 1 && numericValue <= this.state.createConnectors) {
            this.setState({
                [`anchor${anchorNumber}/port`]: numericValue
            })
        }
    }

    onAnchorRepeatChanged(anchorNumber, checked) {
        this.setState({
            [`anchor${anchorNumber}/repeat`]: checked
        })
    }

    onAnchorRevertChanged(anchorNumber, checked) {
        this.setState({
            [`anchor${anchorNumber}/revert`]: checked
        })
    }

    onAddAnchor() {
        if (this.state.createNumAnchors < this.state.createConnectors) {
            const anchorNumber = this.state.createNumAnchors
            this.setState({
                createNumAnchors: anchorNumber + 1,
                [`anchor${anchorNumber}/cp1Signal`]: 0.001,
                [`anchor${anchorNumber}/cp1Force`]: 0,
                [`anchor${anchorNumber}/cp2Signal`]: 0.005,
                [`anchor${anchorNumber}/cp2Force`]: 20,
                [`anchor${anchorNumber}/startSignal`]: 0.001,
                [`anchor${anchorNumber}/targetSignal`]: 0.005,
                [`anchor${anchorNumber}/duration`]: 1440,
                [`anchor${anchorNumber}/port`]: this.findFreePort(),
                [`anchor${anchorNumber}/repeat`]: true,
                [`anchor${anchorNumber}/revert`]: false
            })
        }
    }

    findFreePort() {
        const ports = new Set([...Array(this.state.createConnectors).keys()].map(i => i+1));
        [...Array(this.state.createNumAnchors).keys()]
            .map(anchorNumber => this.state[`anchor${anchorNumber}/port`])
            .forEach(port => ports.delete(port));
        return ports.values().next().value
    }

    onSubmit() {
        const request = {
            firmwareVersion: this.state.createFirmwareVersion,
            batteryLevel: this.state.createBatteryLevel / 100.0,
            errorMessage: this.state.createErrorMessage.length > 0 ? this.state.createErrorMessage : null,
            connectors: this.state.createConnectors,
            anchors: [...Array(this.state.createNumAnchors).keys()]
                .map(anchorNumber => {
                    return {
                        port: this.state[`anchor${anchorNumber}/port`],
                        signal1: this.state[`anchor${anchorNumber}/cp1Signal`],
                        force1: this.state[`anchor${anchorNumber}/cp1Force`],
                        signal2: this.state[`anchor${anchorNumber}/cp2Signal`],
                        force2: this.state[`anchor${anchorNumber}/cp2Force`],
                        startSignal: this.state[`anchor${anchorNumber}/startSignal`],
                        targetSignal: this.state[`anchor${anchorNumber}/targetSignal`],
                        durationMinutes: this.state[`anchor${anchorNumber}/duration`],
                        repeat: this.state[`anchor${anchorNumber}/repeat`],
                        revert: this.state[`anchor${anchorNumber}/revert`]
                    }
                })
        }
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        }
        fetch(`${AppSettings.rootPath}/v1/simulations`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({activeTabKey: 0}, () => this.loadSimulations())
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }
}

export default SimulationList