import React, { Component } from "react";
import { Translation } from "react-i18next";

import {
    Alert,
    Button,
    DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    DrawerContentBody,
    ExpandableSection,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    Modal,
    ModalVariant,
    TextArea,
    TextInput
} from "@patternfly/react-core";

import {
    PauseIcon,
    PlayIcon,
    QuestionCircleIcon
} from "@patternfly/react-icons";

import { AppSettings } from "./AppSettings";

import "./DetailsDrawer.css";

class SimulationDetails extends Component {
    static GATEWAY_FIELDS = [
        {key: "serialNumber", label: "fe_common_serial-number"},
        {key: "status", label: "fe_common_status"},
        {key: "errorMessage", label: "fe_simulator_error-message"},
        {key: "firmwareVersion", label: "fe_simulator_installed-firmware"},
        {key: "batteryLevel", label: "fe_common_remaining-battery"},
        {key: "connectors", label: "fe_simulator_num-ports"},
        {key: "lastConfigAt", label: "fe_common_configured-at"},
        {key: "lastHeartbeatAt", label: "fe_simulator_last-update"}
    ]

    static ANCHOR_FIELDS = [
        {key: "serialNumber", label: "fe_common_serial-number"},
        {key: "port", label: "fe_anchor_port"},
        {key: "signal1", label: "anchor_prop_factory-cp1-signal"},
        {key: "force1", label: "anchor_prop_factory-cp1-load"},
        {key: "signal2", label: "anchor_prop_factory-cp2-signal"},
        {key: "force2", label: "anchor_prop_factory-cp2-load"},
        {key: "startSignal", label: "fe_simulator_signal-start"},
        {key: "targetSignal", label: "fe_simulator_signal-end"},
        {key: "durationMinutes", label: "fe_simulator_duration"},
        {key: "repeat", label: "fe_simulator_repeat"},
        {key: "revert", label: "fe_simulator_reverse"},
        {key: "forceSignal", label: "fe_simulator_signal-fixed"},
        {key: "forceTemperature", label: "fe_simulator_temperature-fixed"},
        {key: "minForceThreshold", label: "fe_simulator_load-lower-threshold"},
        {key: "maxForceThreshold", label: "fe_simulator_load-lower-threshold"},
        {key: "minTemperatureThreshold", label: "fe_simulator_temperature-lower-threshold"},
        {key: "maxTemperatureThreshold", label: "fe_simulator_temperature-upper-threshold"},
        {key: "measuredSince", label: "fe_simulator_measuring-since"},
        {key: "lastSimulatedSignal", label: "fe_simulator_last-simulated-signal"}
    ]

    constructor(props) {
        super(props)

        this.state = {
            details: {
                anchors: []
            },
            expandedSections: {},
            showModal: false,
            modalPropertyKey: "",
            modalPropertyValue: "",
            modalAnchorId: ""
        }

        this.loadDetails = this.loadDetails.bind(this)
        this.getStatusAlertIcon = this.getStatusAlertIcon.bind(this)
        this.getStatusAlertVariant = this.getStatusAlertVariant.bind(this)
        this.getStatus = this.getStatus.bind(this)
        this.expandSection = this.expandSection.bind(this)
        this.showErrorMessageModal = this.showErrorMessageModal.bind(this)
        this.showBatteryLevelModal = this.showBatteryLevelModal.bind(this)
        this.showForceSignalModal = this.showForceSignalModal.bind(this)
        this.showForceTemperatureModal = this.showForceTemperatureModal.bind(this)
        this.renderModal = this.renderModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.confirmModal = this.confirmModal.bind(this)
        this.onModalPropertyChanged = this.onModalPropertyChanged.bind(this)
        this.onToggleSimulation = this.onToggleSimulation.bind(this)
        this.onResetAnchorClicked = this.onResetAnchorClicked.bind(this)
    }

    loadDetails() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/simulations/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState({
                            details: data,
                            expandedSections: {}
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    componentDidMount() {
        this.loadDetails()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.serialNumber !== prevProps.serialNumber) {
            this.loadDetails()
        }
    }

    render() {
        return (
            <Translation>
                { t =>
                    <DrawerContentBody className="DetailsDrawer">
                        <Alert customIcon={this.getStatusAlertIcon()} variant={this.getStatusAlertVariant()} isInline
                               title={this.getStatus(t)}/>
                        <Flex className="DetailsDrawerButtonContainer">
                            <FlexItem>
                                <Button variant="primary"
                                        onClick={this.onToggleSimulation}
                                >
                                    {this.state.details.started ? t("fe_simulator_stop") : t("fe_simulator_start")}
                                </Button>
                            </FlexItem>
                            <FlexItem>
                                <Button variant="secondary" onClick={this.showErrorMessageModal}>{t("simulation_status_error")}</Button>
                            </FlexItem>
                            <FlexItem>
                                <Button variant="secondary" onClick={this.showBatteryLevelModal}>{t("fe_simulator_battery-charge")}</Button>
                            </FlexItem>
                        </Flex>
                        <DataList isCompact aria-label="details">
                            {
                                SimulationDetails.GATEWAY_FIELDS.map((item) => {
                                    return (
                                        <DataListItem key={item.key}>
                                            <DataListItemRow>
                                                <DataListItemCells dataListCells={[
                                                    <DataListCell key="label"
                                                                  isFilled={false}>{t(item.label)}</DataListCell>,
                                                    <DataListCell key="value" isFilled={false}
                                                                  alignRight
                                                                  className="Value">{this.state.details[item.key]}</DataListCell>
                                                ]}/>
                                            </DataListItemRow>
                                        </DataListItem>
                                    )
                                })
                            }
                        </DataList>
                        {
                            this.state.details.anchors.map((anchor => {
                                return this.renderSensorAnchorExpandable(anchor, t)
                            }))
                        }
                        {
                            this.state.showModal && this.renderModal(t)
                        }
                    </DrawerContentBody>
                }
            </Translation>
        )
    }

    renderSensorAnchorExpandable(anchor, t) {
        const anchorId = anchor["serialNumber"]
        const toggleText = t("fe_configured-anchor-section_title").replace("%", anchor["port"])
        const isExpanded = this.state.expandedSections[anchorId]
        return (
            <ExpandableSection key={anchorId} toggleText={toggleText} isExpanded={isExpanded}
                               onToggle={(isExpanded) => this.expandSection(anchorId, isExpanded)}>
                <DataList isCompact aria-label={anchorId}>
                    {
                        SimulationDetails.ANCHOR_FIELDS.map((item) => {
                            return (
                                <DataListItem key={item.key}>
                                    <DataListItemRow>
                                        <DataListItemCells dataListCells={[
                                            <DataListCell key="label" isFilled={false}>{t(item.label)}</DataListCell>,
                                            <DataListCell key="value" isFilled={false}
                                                          alignRight className="Value">{anchor[item.key] || ""}</DataListCell>
                                        ]}/>
                                    </DataListItemRow>
                                </DataListItem>
                            )
                        })
                    }
                    <Flex className="DetailsDrawerButtonContainer">
                        <FlexItem>
                            <Button variant="secondary" onClick={() => this.onResetAnchorClicked(anchorId, t)}>
                                {t("fe_simulator_reset-action")}
                            </Button>
                        </FlexItem>
                        <FlexItem>
                            <Button variant="secondary" onClick={() => this.showForceSignalModal(anchorId)}>
                                {t("fe_simulator_signal-action")}
                            </Button>
                        </FlexItem>
                        <FlexItem>
                            <Button variant="secondary" onClick={() => this.showForceTemperatureModal(anchorId)}>
                                {t("fe_simulator_temperature-action")}
                            </Button>
                        </FlexItem>
                    </Flex>
                </DataList>
            </ExpandableSection>
        )
    }

    renderModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_modal-title")}
                isOpen={this.state.showModal}
                onClose={this.closeModal}
                actions={[
                    <Button key="confirm" variant="primary" form="modal-with-form-form"
                            onClick={() => this.confirmModal(false)}
                    >
                        {t("fe_common_ok")}
                    </Button>
                ]}
            >
                <Form id="modal-with-form-form" onSubmit={(event) => this.confirmModal(event)}>
                    {
                        this.state.modalPropertyKey === "errorMessage" && (
                            <FormGroup label={t("fe_simulator_error-message")} fieldId="errorMessage"
                                       helperText={t("fe_simulator_change-error-message-hint")}>
                                <TextArea id="errorMessage" name="errorMessage" autoResize
                                          onChange={this.onModalPropertyChanged} resizeOrientation="vertical"
                                          value={this.state.modalPropertyValue}
                                />
                            </FormGroup>
                        )
                    }
                    {
                        this.state.modalPropertyKey === "batteryLevel" && (
                            <FormGroup label={t("fe_simulator_battery-charge")} fieldId="batteryLevel"
                                       helperText={t("fe_simulator_change-battery-charge-hint")}>
                                <TextInput id="batteryLevel" name="batteryLevel" type="number"
                                           placeholder={t("fe_simulator_change-battery-charge")}
                                           onChange={this.onModalPropertyChanged}
                                           value={this.state.modalPropertyValue}
                                />
                            </FormGroup>
                        )
                    }
                    {
                        this.state.modalPropertyKey === "forceSignal" && (
                            <FormGroup label={t("fe_simulator_signal-fixed")} fieldId="forceSignal"
                                       helperText={t("fe_simulator_signal-fixed-hint")}>
                                <TextInput id="forceSignal" name="forceSignal" type="number"
                                           placeholder={t("fe_simulator_signal")}
                                           onChange={this.onModalPropertyChanged}
                                           value={this.state.modalPropertyValue}
                                />
                            </FormGroup>
                        )
                    }
                    {
                        this.state.modalPropertyKey === "forceTemperature" && (
                            <FormGroup label={t("fe_simulator_temperature-fixed")} fieldId="forceTemperature"
                                       helperText={t("fe_simulator_temperature-fixed-hint")}>
                                <TextInput id="forceTemperature" name="forceTemperature" type="number"
                                           placeholder={t("fe_simulator_temperature")}
                                           onChange={this.onModalPropertyChanged}
                                           value={this.state.modalPropertyValue}
                                />
                            </FormGroup>
                        )
                    }
                </Form>
            </Modal>
        )
    }

    getStatusAlertIcon() {
        if (this.state.details) {
            return this.state.details.started ? <PlayIcon/> : <PauseIcon/>
        }
        return <QuestionCircleIcon/>
    }

    getStatusAlertVariant() {
        if (this.state.details) {
            return this.state.details.started ? "success" : "danger"
        }
        return "default"
    }

    getStatus(t) {
        if (this.state.details) {
            return this.state.details["localizedStarted"]
        }
        return t("fe_common_unknown")
    }

    expandSection(sectionId, isExpanded) {
        this.setState(prevState => {
            prevState.expandedSections[sectionId] = isExpanded
            return {
                expandedSections: prevState.expandedSections
            }
        })
    }

    showErrorMessageModal() {
        this.setState({
            showModal: true,
            modalPropertyKey: "errorMessage",
            modalPropertyValue: ""
        })
    }

    showBatteryLevelModal() {
        this.setState({
            showModal: true,
            modalPropertyKey: "batteryLevel",
            modalPropertyValue: "100"
        })
    }

    showForceSignalModal(anchorId) {
        this.setState({
            showModal: true,
            modalPropertyKey: "forceSignal",
            modalPropertyValue: "",
            modalAnchorId: anchorId
        })
    }

    showForceTemperatureModal(anchorId) {
        this.setState({
            showModal: true,
            modalPropertyKey: "forceTemperature",
            modalPropertyValue: "",
            modalAnchorId: anchorId
        })
    }

    onModalPropertyChanged(value) {
        if (this.state.modalPropertyKey === "batteryLevel") {
            if (value < 0) {
                value = "0"
            } else if (value > 100) {
                value = "100"
            }
        }
        this.setState({modalPropertyValue: value})
    }

    closeModal() {
        this.setState({showModal: false})
    }

    confirmModal(event) {
        event && event.preventDefault()
        this.setState({showModal: false})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                key: this.state.modalPropertyKey,
                value: this.state.modalPropertyValue,
                anchorId: this.state.modalAnchorId || null
            })
        }
        fetch(`${AppSettings.rootPath}/v1/simulations/${this.props.serialNumber}/configure`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.loadDetails()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onToggleSimulation() {
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
            }
        }
        fetch(`${AppSettings.rootPath}/v1/simulations/${this.props.serialNumber}/toggle`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.props.onSimulationToggle()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onResetAnchorClicked(anchorId) {
        const requestInit = {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
            }
        }
        fetch(`${AppSettings.rootPath}/v1/simulations/${this.props.serialNumber}/anchor/${anchorId}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.loadDetails()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }
}

export default SimulationDetails