import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from 'i18next';

import {
    Button,
    DrawerHead,
    DrawerPanelContent,
    Dropdown,
    DropdownDirection,
    DropdownItem,
    DropdownToggle,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalVariant,
    Panel,
    PanelFooter,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Slider,
    Tab,
    TabContent,
    Tabs,
    TabTitleText,
    Text,
    TextInput,
    TextVariants,
    ToggleGroup,
    ToggleGroupItem
} from "@patternfly/react-core";

import {
    AngleLeftIcon,
    AngleRightIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    QuestionCircleIcon,
    SyncAltIcon, UndoIcon
} from "@patternfly/react-icons";

import {
    LS_KEY_CHART_HEIGHT,
    LS_KEY_FILL_AREA,
    LS_KEY_NATURAL_SCALING,
    LS_KEY_PANEL_SIZE,
    LS_KEY_SHOW_DOTS,
    LS_KEY_SHOW_FORCES,
    LS_KEY_SHOW_TEMPERATURES,
    LS_KEY_SMOOTH_LINES
} from "./LocalStorageKeys";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import {
    addDays,
    addSeconds,
    endOfDay
} from "date-fns";
import { de, enUS, fr, it } from "date-fns/locale";

import {
    AiOutlineAreaChart,
    BsThermometerHalf,
    CgExport,
    MdZoomOutMap,
    RiScales2Fill,
    TbVectorBezier2,
    VscGraphScatter
} from "react-icons/all";

import {
    stickyChartsDateRanges,
    stickyChartsInputRanges
} from "./StickyChartsDateRanges";

import streamSaver from "streamsaver";

import StickyChart from "./StickyChart";
import { AppSettings } from "./AppSettings";

import {
    formatDateRange,
    formatNumber
} from "./Utils";

import "./StickyCharts.css";

class StickyCharts extends Component {
    static LABELS = ["Belastung", "Temperatur"]
    static UNITS = ["kN", "°C"]
    static CHART_PADDING = 60
    static DEFAULT_DURATION_DAYS = 30
    static MIN_DURATION_MILLIS = 60_000
    static ANIMATION_DELAY_MILLIS = 500

    static status2Icon(statusEnum) {
        switch (statusEnum) {
        case "IncompleteAnchorSettings":
        case "NotCalibrated":
            return <ExclamationTriangleIcon className="Warning"/>
        case "Operational":
            return <CheckCircleIcon className="Success"/>
        case "Error":
            return <ExclamationCircleIcon className="Danger"/>
        default:
            return <QuestionCircleIcon className="Unknown"/>
        }
    }

    static updateReloadOnNextPage(chartEndDate) {
        return endOfDay(new Date()).getTime() === chartEndDate.getTime()
    }

    constructor(props) {
        super(props)

        const endDate = new Date()
        const startDate = addDays(endDate, -StickyCharts.DEFAULT_DURATION_DAYS)

        this.panelRef = React.createRef()
        this.tabContentRef = React.createRef()
        this.chartsLoaded = 0
        this.refreshTimerId = false

        this.state = {
            drawerHeight: Number(localStorage.getItem(LS_KEY_PANEL_SIZE) || "400"),
            chartHeight: Number(localStorage.getItem(LS_KEY_CHART_HEIGHT) || "300"),
            showForceCharts: localStorage.getItem(LS_KEY_SHOW_FORCES) !== "n",
            showTemperatureCharts: localStorage.getItem(LS_KEY_SHOW_TEMPERATURES) !== "n",
            smoothLines: localStorage.getItem(LS_KEY_SMOOTH_LINES) === "y",
            showDots: localStorage.getItem(LS_KEY_SHOW_DOTS) !== "n",
            naturalScaling: localStorage.getItem(LS_KEY_NATURAL_SCALING) === "y",
            fillArea: localStorage.getItem(LS_KEY_FILL_AREA) !== "n",
            selectedGatewayId: null,
            showDateRangeModal: false,
            dateRanges: [{
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            }],
            animateCharts: true,
            chartStartDate: startDate,
            chartEndDate: endDate,
            anchors: [],
            maxHeight: 0,
            width: 0,
            isAnchorDropdownOpen: false,
            reloadOnNextPage: true,
            useIcons: false,
            hoverX: false,
            prevPageProgress: false,
            nextPageProgress: false,
            showForecastCharts: false,
            showForecastingSetupModal: false,
            forecastingDurationValue: 10,
            forecastingDurationUnit: "d",
            forecastDurationDropdownOpen: false,
            dateRangeBeforeZoom: false
        }

        this.onTabSelect = this.onTabSelect.bind(this)
        this.handleDrawerResize = this.handleDrawerResize.bind(this)
        this.handleWindowResize = this.handleWindowResize.bind(this)
        this.handleCloseTab = this.handleCloseTab.bind(this)
        this.renderDateRangeModal = this.renderDateRangeModal.bind(this)
        this.setDateRange = this.setDateRange.bind(this)
        this.zoomDateRange = this.zoomDateRange.bind(this)
        this.resetZoom = this.resetZoom.bind(this)
        this.closeDateRangeModal = this.closeDateRangeModal.bind(this)
        this.editDateRange = this.editDateRange.bind(this)
        this.loadChartAnchors = this.loadChartAnchors.bind(this)
        this.toggleShowForces = this.toggleShowForces.bind(this)
        this.toggleShowTemperatures = this.toggleShowTemperatures.bind(this)
        this.toggleShowForecasts = this.toggleShowForecasts.bind(this)
        this.toggleSmoothLines = this.toggleSmoothLines.bind(this)
        this.toggleShowDots = this.toggleShowDots.bind(this)
        this.toggleFillArea = this.toggleFillArea.bind(this)
        this.toggleNaturalScaling = this.toggleNaturalScaling.bind(this)
        this.getActiveTabKey = this.getActiveTabKey.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.reloadAndSetEndDateToEndOfDay = this.reloadAndSetEndDateToEndOfDay.bind(this)
        this.onChartHeightChange = this.onChartHeightChange.bind(this)
        this.onAnchorDropdownSelect = this.onAnchorDropdownSelect.bind(this)
        this.exportAsCsv = this.exportAsCsv.bind(this)
        this.updateReloadOnNextPage = this.updateReloadOnNextPage.bind(this)
        this.chartTelemetryLoaded = this.chartTelemetryLoaded.bind(this)
        this.renderConfigureForecastModal = this.renderConfigureForecastModal.bind(this)
    }

    componentDidMount() {
        this.handleWindowResize()
        this.loadChartAnchors()

        window.addEventListener("resize", this.handleWindowResize)
        if (this.props.isExpanded) {
            this.refreshTimerId = window.setInterval(this.reloadAndSetEndDateToEndOfDay, 60 * 1_000)
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize)
        if (this.refreshTimerId !== false) {
            window.clearInterval(this.refreshTimerId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.current !== prevProps.current) {
            this.loadChartAnchors()
        }
        if (this.props.isNavOpen !== prevProps.isNavOpen) {
            this.handleWindowResize()
        }
        if (this.state.reloadOnNextPage !== prevState.reloadOnNextPage) {
            if (this.state.reloadOnNextPage) {
                this.refreshTimerId = window.setInterval(this.reloadAndSetEndDateToEndOfDay, 60 * 1_000)
            } else if (this.refreshTimerId !== false) {
                window.clearInterval(this.refreshTimerId)
                this.refreshTimerId = false
            }
        }
    }

    render() {
        return (
            <Translation>
                { t =>
                    <DrawerPanelContent className="StickyCharts" isResizable defaultSize={`${this.state.drawerHeight}px`} minSize={"400px"} onResize={this.handleDrawerResize}>
                        <DrawerHead>
                            <div ref={this.panelRef}>
                                <Panel isScrollable style={{background: "none"}}>
                                    <PanelHeader>
                                        <Tabs tabIndex={this.props.isExpanded ? 0 : -1} activeKey={this.getActiveTabKey()} onSelect={this.onTabSelect} onClose={this.handleCloseTab} isBox={true}>
                                            {
                                                this.props.gateways.map((serialNumber, index) => (
                                                    <Tab key={serialNumber} eventKey={index} tabContentId="tabContentId" tabContentRef={this.tabContentRef} title={<TabTitleText>{serialNumber}</TabTitleText>}/>
                                                ))
                                            }
                                        </Tabs>
                                    </PanelHeader>
                                    <PanelMain maxHeight={`${this.state.drawerHeight - 82 - 76}px`}>
                                        <PanelMainBody style={{height: `${this.state.anchors.length*(this.state.chartHeight+46)}px`}}>
                                            {
                                                <TabContent className="StickyChartsTabContent" eventKey={0} id="tabContentId" ref={this.tabContentRef} tabIndex={-1}>
                                                    {
                                                        this.state.anchors.map(anchor => (
                                                            <div key={anchor.anchorId}>
                                                                <Flex className="ChartHeader"
                                                                      justifyContent={{default: "justifyContentSpaceBetween"}}>
                                                                    <FlexItem>
                                                                        <Label icon={StickyCharts.status2Icon(anchor["statusEnum"])}>
                                                                            {anchor.profile && t("fe_chart_connected-anchor").replace("%1", anchor.anchorId).replace("%2", anchor["connector"]).replace("%3", anchor["status"])}
                                                                            {!anchor.profile && (anchor.anchorId + "- " + anchor["status"])}
                                                                        </Label>
                                                                    </FlexItem>
                                                                    <FlexItem>
                                                                        {
                                                                            anchor.profile["lastForce"] !== undefined && this.state.showForceCharts &&
                                                                            <Label style={{marginLeft: "1em"}} color="red">
                                                                                {t("fe_chart_last-load")}&nbsp;<b>{formatNumber(anchor.profile["lastForce"], 2, t)} kN</b>
                                                                            </Label>
                                                                        }
                                                                        {
                                                                            anchor.profile["lastTemperature"] !== undefined && this.state.showTemperatureCharts &&
                                                                            <Label style={{marginLeft: "1em"}} color="blue">
                                                                                {t("fe_chart_last-temperature")}&nbsp;<b>{formatNumber(anchor.profile["lastTemperature"], 2, t)} °C</b>
                                                                            </Label>
                                                                        }
                                                                    </FlexItem>
                                                                </Flex>
                                                                <StickyChart
                                                                    t={t}
                                                                    app={this.props.app}
                                                                    telemetryId={anchor.telemetryId}
                                                                    animations={this.state.animateCharts}
                                                                    startDate={this.state.chartStartDate}
                                                                    endDate={this.state.chartEndDate}
                                                                    width={this.state.width - StickyCharts.CHART_PADDING}
                                                                    height={this.state.chartHeight}
                                                                    showLoadChart={this.state.showForceCharts}
                                                                    showTemperatureChart={this.state.showTemperatureCharts}
                                                                    naturalScaling={this.state.naturalScaling}
                                                                    fillArea={this.state.fillArea}
                                                                    showDots={this.state.showDots}
                                                                    smoothLines={this.state.smoothLines}
                                                                    setChartTelemetryLoaded={this.chartTelemetryLoaded}
                                                                    setDateRangeTo={this.zoomDateRange}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                </TabContent>
                                            }
                                        </PanelMainBody>
                                    </PanelMain>
                                    <PanelFooter>
                                        <Flex justifyContent={{default: "justifyContentSpaceBetween"}}>
                                            <Flex>
                                                <FlexItem alignSelf={{default: "alignSelfCenter"}}>
                                                    <ToggleGroup>
                                                        <ToggleGroupItem
                                                            className="ForceSwitch"
                                                            isSelected={this.state.showForceCharts}
                                                            onChange={this.toggleShowForces}
                                                            aria-label={t("fe_chart_load-with-unit")}
                                                            text={!this.state.useIcons && t("fe_chart_load-with-unit")}
                                                            icon={this.state.useIcons && <RiScales2Fill className="ToolbarIcon"/>}
                                                        />
                                                        <ToggleGroupItem
                                                            className="TemperatureSwitch"
                                                            isSelected={this.state.showTemperatureCharts}
                                                            onChange={this.toggleShowTemperatures}
                                                            aria-label={t("fe_chart_temperature-with-unit")}
                                                            text={!this.state.useIcons && t("fe_chart_temperature-with-unit")}
                                                            icon={this.state.useIcons && <BsThermometerHalf className="ToolbarIcon"/>}
                                                        />
                                                    </ToggleGroup>
                                                </FlexItem>
                                                <FlexItem alignSelf={{default: "alignSelfCenter"}}>
                                                    <ToggleGroup>
                                                        <ToggleGroupItem
                                                            isSelected={this.state.naturalScaling}
                                                            onChange={this.toggleNaturalScaling}
                                                            aria-label={t("fe_chart_natural-scale")}
                                                            text={!this.state.useIcons && t("fe_chart_natural-scale")}
                                                            icon={this.state.useIcons && <MdZoomOutMap className="ToolbarIcon"/>}
                                                        />
                                                        <ToggleGroupItem
                                                            isSelected={this.state.smoothLines}
                                                            onChange={this.toggleSmoothLines}
                                                            aria-label={t("fe_chart_smoothing")}
                                                            text={!this.state.useIcons && t("fe_chart_smoothing")}
                                                            icon={this.state.useIcons && <TbVectorBezier2 className="ToolbarIcon"/>}
                                                        />
                                                        <ToggleGroupItem
                                                            isSelected={this.state.fillArea}
                                                            onChange={this.toggleFillArea}
                                                            aria-label={t("fe_chart_fill")}
                                                            text={!this.state.useIcons && t("fe_chart_fill")}
                                                            icon={this.state.useIcons && <AiOutlineAreaChart className="ToolbarIcon"/>}
                                                        />
                                                        <ToggleGroupItem
                                                            isSelected={this.state.showDots}
                                                            onChange={this.toggleShowDots}
                                                            aria-label={t("fe_chart_dots")}
                                                            text={!this.state.useIcons && t("fe_chart_dots")}
                                                            icon={this.state.useIcons && <VscGraphScatter className="ToolbarIcon"/>}
                                                        />
                                                    </ToggleGroup>
                                                </FlexItem>
                                                <FlexItem style={{width: "200px"}}
                                                          alignSelf={{default: "alignSelfCenter"}}>
                                                    <div style={{textAlign: "center"}}>
                                                        <Text component={TextVariants.small}>{t("fe_chart_size")}</Text>
                                                    </div>
                                                    <Slider
                                                        min={200}
                                                        max={800}
                                                        step={1}
                                                        showBoundaries={false}
                                                        value={this.state.chartHeight}
                                                        onChange={this.onChartHeightChange}
                                                    />
                                                </FlexItem>
                                            </Flex>
                                            <Flex>
                                                <FlexItem alignSelf={{default: "alignSelfCenter"}}>
                                                    <Dropdown
                                                        direction={DropdownDirection.up}
                                                        onSelect={this.onAnchorDropdownSelect}
                                                        isOpen={this.state.isAnchorDropdownOpen}
                                                        toggle={(
                                                            <DropdownToggle
                                                                id="anchor-select-toggle"
                                                                onToggle={(isOpen) => this.setState({isAnchorDropdownOpen: isOpen})}
                                                                aria-label={t("fe_action_export-csv")}
                                                            >
                                                                {!this.state.useIcons && t("fe_action_export-csv")}
                                                                {this.state.useIcons && <CgExport className="ToolbarIcon"/>}
                                                            </DropdownToggle>
                                                        )}
                                                        dropdownItems={
                                                            this.state.anchors.map(anchor => (
                                                                <DropdownItem
                                                                    key={anchor.anchorId}
                                                                    component="button"
                                                                    onClick={() => this.exportAsCsv(anchor)}
                                                                >
                                                                    {anchor.anchorId}
                                                                </DropdownItem>
                                                            ))
                                                        }
                                                    />
                                                </FlexItem>
                                            </Flex>
                                            <Flex>
                                                <FlexItem
                                                    align={{default: "alignRight"}}
                                                    alignSelf={{default: "alignSelfCenter"}}
                                                >
                                                    <Button variant="control" onClick={this.previousPage}>
                                                        {this.state.prevPageProgress && <SyncAltIcon className="ProgressAnimation"/>}
                                                        {!this.state.prevPageProgress && <AngleLeftIcon/>}
                                                    </Button>
                                                    <Button variant="control" className="PickDate"
                                                            onClick={() => this.setState({showDateRangeModal: true})}
                                                    >
                                                        {formatDateRange(this.state.chartStartDate, this.state.chartEndDate)}
                                                    </Button>
                                                    {
                                                        this.state.dateRangeBeforeZoom &&
                                                        <Button variant="control" onClick={this.resetZoom}>
                                                            <UndoIcon/>
                                                        </Button>
                                                    }
                                                    <Button variant="control"
                                                            onClick={() => this.state.reloadOnNextPage ? this.reloadAndSetEndDateToEndOfDay() : this.nextPage()}
                                                    >
                                                        {(this.state.nextPageProgress) && <SyncAltIcon className="ProgressAnimation"/>}
                                                        {(!this.state.nextPageProgress && this.state.reloadOnNextPage) && <SyncAltIcon/>}
                                                        {(!this.state.nextPageProgress && !this.state.reloadOnNextPage) && <AngleRightIcon/>}
                                                    </Button>
                                                </FlexItem>
                                            </Flex>
                                        </Flex>
                                    </PanelFooter>
                                </Panel>
                            </div>
                        </DrawerHead>
                        {
                            this.state.showDateRangeModal && this.renderDateRangeModal(t)
                        }
                        {
                            this.state.showForecastingSetupModal && this.renderConfigureForecastModal(t)
                        }
                    </DrawerPanelContent>
                }
            </Translation>
        )
    }

    renderDateRangeModal(t) {
        return (
            <Modal
                width={950}
                variant={ModalVariant.small}
                title={t("fe_chart_select-period")}
                isOpen={true}
                onClose={this.closeDateRangeModal}
                actions={[
                    <Button key="confirm" variant="primary" onClick={this.setDateRange}>{t("fe_common_ok")}</Button>,
                    <Button key="cancel" variant="link" onClick={this.closeDateRangeModal}>{t("fe_common_cancel")}</Button>
                ]}
            >
                <DateRangePicker
                    onChange={this.editDateRange}
                    showSelectionPreview
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={this.state.dateRanges}
                    direction="horizontal"
                    locale={
                        i18n.language.startsWith("de") ? de
                        : i18n.language.startsWith("fr") ? fr
                        : i18n.language.startsWith("it") ? it
                        : enUS
                    }
                    maxDate={new Date()}
                    dateDisplayFormat={t("fe_chart_period-selector-date-pattern")}
                    monthDisplayFormat="MMMM yyyy"
                    staticRanges={stickyChartsDateRanges(t)}
                    inputRanges={stickyChartsInputRanges(t)}
                />
            </Modal>
        )
    }

    onTabSelect(_, tabIndex) {
        this.props.selectStickyGateway(this.props.gateways[tabIndex])
    }

    handleDrawerResize(newSize) {
        newSize = Math.min(this.state.maxHeight, Math.max(400, newSize))
        localStorage.setItem(LS_KEY_PANEL_SIZE, newSize.toString())
        this.setState({
            drawerHeight: newSize
        })
    }

    handleWindowResize() {
        const width = window.innerWidth
        const useWidth = width - (this.props.isNavOpen && width >= 1200 ? 290 : 0)
        this.setState({
            maxHeight: window.innerHeight - 76,
            width: useWidth,
            useIcons: useWidth <= 1310
        })
    }

    handleCloseTab(event, tabIndex) {
        this.props.removeGateway(this.props.gateways[tabIndex])
    }

    closeDateRangeModal() {
        this.setState(prevState => {
            return {
                showDateRangeModal: false,
                dateRanges: [{
                    startDate: prevState.chartStartDate,
                    endDate: prevState.chartEndDate,
                    key: "selection"
                }]
            }
        })
    }

    editDateRange(item) {
        const { startDate, endDate } = item["selection"]
        this.setState({
            dateRanges: [{
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            }]
        })
    }

    setDateRange() {
        this.setState(prevState => {
            let startDate = prevState.dateRanges[0].startDate
            let endDate = prevState.dateRanges[0].endDate
            if (startDate.getTime() === endDate.getTime()) {
                endDate = addSeconds(addDays(endDate, 1), -1)
                const eod = endOfDay(new Date())
                if (endDate.getTime() > eod.getTime()) {
                    endDate = eod
                }
            }
            return {
                chartStartDate: startDate,
                chartEndDate: endOfDay(endDate),
                showDateRangeModal: false,
                nextPageProgress: "ProgressAnimation",
                dateRangeBeforeZoom: false
            }
        }, () => this.updateReloadOnNextPage())
    }

    zoomDateRange(startDate, endDate) {
        this.setState(prevState => ({
            animateCharts: false,
            chartStartDate: startDate,
            chartEndDate: endDate,
            dateRanges: [{
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            }],
            nextPageProgress: "ProgressAnimation",
            reloadOnNextPage: StickyCharts.updateReloadOnNextPage(endDate),
            dateRangeBeforeZoom: prevState.dateRangeBeforeZoom !== false ? prevState.dateRangeBeforeZoom : {startDate: prevState.chartStartDate, endDate: prevState.chartEndDate}
        }))
    }

    resetZoom() {
        this.setState(prevState => ({
            chartStartDate: prevState.dateRangeBeforeZoom.startDate,
            chartEndDate: prevState.dateRangeBeforeZoom.endDate,
            dateRanges: [{
                startDate: prevState.dateRangeBeforeZoom.startDate,
                endDate: prevState.dateRangeBeforeZoom.endDate,
                key: "selection"
            }],
            nextPageProgress: "ProgressAnimation",
            dateRangeBeforeZoom: false
        }))
    }

    loadChartAnchors(callback) {
        if (!this.props.current) return

        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorGateways/details/${this.props.current}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response
                        .json()
                        .then(data => {
                            this.setState({
                                anchors: data.anchors
                            }, () => callback && callback())
                        })
                }
            })
            .catch(error => this.setState({prevPageProgress: false, nextPageProgress: false}, () => this.props.app.showErrorMessage(error.message)))
    }

    getActiveTabKey() {
        return this.props.gateways.findIndex(s => s === this.props.current)
    }

    toggleShowForces(show) {
        localStorage.setItem(LS_KEY_SHOW_FORCES, show ? "y" : "n")
        this.setState({
            showForceCharts: show
        })
    }

    toggleShowTemperatures(show) {
        localStorage.setItem(LS_KEY_SHOW_TEMPERATURES, show ? "y" : "n")
        this.setState({
            showTemperatureCharts: show
        })
    }

    toggleShowForecasts(show) {
        if (show) {
            this.setState({showForecastingSetupModal: true})
        } else {
            this.setState({showForecastCharts: false})
        }
    }

    toggleSmoothLines(enabled) {
        localStorage.setItem(LS_KEY_SMOOTH_LINES, enabled ? "y" : "n")
        this.setState({
            smoothLines: enabled
        })
    }

    toggleShowDots(enabled) {
        localStorage.setItem(LS_KEY_SHOW_DOTS, enabled ? "y" : "n")
        this.setState({
            showDots: enabled
        })
    }

    toggleFillArea(enabled) {
        localStorage.setItem(LS_KEY_FILL_AREA, enabled ? "y" : "n")
        this.setState({
            fillArea: enabled
        })
    }

    toggleNaturalScaling(enabled) {
        localStorage.setItem(LS_KEY_NATURAL_SCALING, enabled ? "y" : "n")
        this.setState({
            naturalScaling: enabled
        })
    }

    onChartHeightChange(height) {
        localStorage.setItem(LS_KEY_CHART_HEIGHT, height.toString())
        this.setState({
            chartHeight: height
        })
    }

    previousPage() {
        this.setState(prevState => {
            const oldStartDate = prevState.chartStartDate
            const newStartDate = new Date(oldStartDate.getTime() - (prevState.chartEndDate.getTime() - oldStartDate.getTime()))
            return {
                chartStartDate: newStartDate,
                chartEndDate: oldStartDate,
                dateRanges: [{
                    startDate: newStartDate,
                    endDate: oldStartDate,
                    key: "selection"
                }],
                prevPageProgress: true,
                dateRangeBeforeZoom: false
            }
        }, () => this.updateReloadOnNextPage())
    }

    nextPage() {
        this.setState(prevState => {
            const duration = prevState.chartEndDate.getTime() - prevState.chartStartDate.getTime()
            const newEndDate = new Date(Math.min(prevState.chartEndDate.getTime() + duration, endOfDay(new Date()).getTime()))
            const newStartDate = new Date(newEndDate.getTime() - duration)
            return {
                chartStartDate: newStartDate,
                chartEndDate: newEndDate,
                dateRanges: [{
                    startDate: newStartDate,
                    endDate: newEndDate,
                    key: "selection"
                }],
                nextPageProgress: "ProgressAnimation",
                dateRangeBeforeZoom: false
            }
        }, () => this.updateReloadOnNextPage())
    }

    reloadAndSetEndDateToEndOfDay() {
        if (!this.props.isExpanded) return
        this.setState({nextPageProgress: "ProgressAnimation"})
        this.loadChartAnchors(() => {
            this.setState(prevState => {
                const duration = prevState.chartEndDate.getTime() - prevState.chartStartDate.getTime()
                const newEndDate = endOfDay(new Date())
                const newStartDate = new Date(newEndDate.getTime() - duration)
                return {
                    chartStartDate: newStartDate,
                    chartEndDate: newEndDate,
                    dateRanges: [{
                        startDate: newStartDate,
                        endDate: newEndDate,
                        key: "selection"
                    }],
                    dateRangeBeforeZoom: false
                }
            }, () => this.updateReloadOnNextPage())
        })
    }

    onAnchorDropdownSelect() {
        this.setState({
            isAnchorDropdownOpen: false
        }, () => {
            const element = document.getElementById("anchor-select-toggle");
            element.focus();
        })
    }

    async exportAsCsv(anchor) {
        this.setState({
            isAnchorDropdownOpen: false
        })

        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        try {
            const response = await fetch(`${AppSettings.rootPath}/v1/telemetry/${anchor.telemetryId}/csvOfRange?fromMillis=${this.state.chartStartDate.getTime()}&toMillis=${this.state.chartEndDate.getTime()}`, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream(`${anchor.anchorId}.csv`)

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            this.props.app.showErrorMessage(error.message)
        }
    }

    updateReloadOnNextPage() {
        this.setState(prevState => {
            return {
                reloadOnNextPage: new Date().getTime() - prevState.chartEndDate.getTime() < 60_000
            }
        })
    }

    chartTelemetryLoaded() {
        if (++this.chartsLoaded === this.state.anchors.length) {
            this.chartsLoaded = 0;
            this.setState({
                prevPageProgress: false,
                nextPageProgress: false
            })
            window.setTimeout(() => this.setState({animateCharts: true}), StickyCharts.ANIMATION_DELAY_MILLIS)
        }
    }

    renderConfigureForecastModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_forecasting")}
                isOpen={this.state.showForecastingSetupModal}
                onClose={() => this.setState({showForecastingSetupModal: false})}
                actions={[
                    <Button
                        key="confirm"
                        variant="primary"
                        form="modal-with-form-form"
                        onClick={() => this.setState({
                            showForecastCharts: true,
                            showForecastingSetupModal: false
                        })}
                    >
                        {t("fe_common_ok")}
                    </Button>
                ]}
            >
                <Form id="modal-with-form-form" onSubmit={() => this.setState({showForecastCharts: true, showForecastingSetupModal: false})}>
                    <FormGroup label={t("fe_anchor_forecasting-duration")} fieldId="duration-value" className="TopSpacer">
                        <Flex>
                            <FlexItem>
                                <TextInput type="number" id="duration-value"
                                           name="duration-value"
                                           value={this.state.forecastingDurationValue}
                                           onChange={(value) => this.setState({forecastingDurationValue: value})}
                                           style={{width:"400px"}}
                                />
                            </FlexItem>
                            <FlexItem>
                                <Dropdown
                                    onSelect={() => this.setState({forecastDurationDropdownOpen: false})}
                                    toggle={
                                        <DropdownToggle
                                            id="duration-unit"
                                            onToggle={() => this.setState(prevState => {return {forecastDurationDropdownOpen: !prevState.forecastDurationDropdownOpen}})}
                                        >
                                            {this.state.forecastingDurationUnit}
                                        </DropdownToggle>
                                    }
                                    isOpen={this.state.forecastDurationDropdownOpen}
                                    dropdownItems={[
                                        <DropdownItem key="m" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "m"})}>m</DropdownItem>,
                                        <DropdownItem key="h" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "h"})}>h</DropdownItem>,
                                        <DropdownItem key="d" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "d"})}>d</DropdownItem>
                                    ]}
                                    menuAppendTo="parent"
                                />
                            </FlexItem>
                        </Flex>
                    </FormGroup>
                </Form>
            </Modal>
        )
    }
}

export default StickyCharts